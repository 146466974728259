export default (context, inject) => {
    function loadFreshchat() {
        if (!window.fcWidget) {
            console.error("Freshchat widget is not loaded")
            return
        }
        window.fcWidget.init({
            token: "0970f79a-205a-421e-9e03-96503a5e021e",
            host: "https://one5commute.freshchat.com"
        })

        // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

        // To set unique user id in your system when it is available
        // window.fcWidget.setExternalId("Guest123")

        // // To set user name
        // window.fcWidget.user.setFirstName("Guest")

        // // To set user email
        // window.fcWidget.user.setEmail("Guest@gmail.com")

        // // To set user properties
        // window.fcWidget.user.setProperties({
        //     plan: "Pro", // meta property 1
        //     status: "Active" // meta property 2
        // })
    }

    function updateFreshchatUser(user) {
        if (window.fcWidget) {
            window.fcWidget.user.setProperties({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                setExternalId: user.setExternalId

                // Add more properties if required
            })
        } else {
            console.error("Freshchat widget is not available for user update")
        }
    }

    // Inject the methods into Nuxt
    inject("freshchat", {
        load: loadFreshchat,
        updateUser: updateFreshchatUser
    })
}
