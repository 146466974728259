import LocalScheme from "@nuxtjs/auth/lib/schemes/local"
import encryptor from "~/plugins/encryption.js"

export default class CustomScheme extends LocalScheme {
    async login(endpoint) {
        this.$auth.ipqualityStatus = true
        if (!this.options.endpoints.login) {
            return
        }

        // Ditch any leftover local tokens before attempting to log in
        await this.$auth.reset()

        // const { response, result } = await this.$auth.request(
        //   endpoint,
        //   this.options.endpoints.login,
        //   true
        // )

        // if (!result) {
        //   const error = new Error('Invalid credentials')
        //   return error
        // }

        if (this.options.tokenRequired) {
            const token = endpoint.data.accessToken

            this.$auth.setToken(this.name, token)
            this._setToken("Bearer " + token)
        }

        if (this.options.autoFetchUser) {
            await this.fetchUser()
        }

        localStorage.removeItem("set_AllMenu")
        localStorage.removeItem("set_AllMenu_timezone")
        return endpoint.data.accessToken
    }
    async getDataFromapi(_accessToken) {
        try {
            const { response } = await this.$auth.request(
                {
                    baseURL: process.env.USER_V2_API_URL,
                    data: { _accessToken }
                },
                this.options.endpoints.user,
                true
            )
            localStorage.setItem(
                "set_OneForUI",
                encryptor.encrypt(JSON.stringify(response))
            )
            if (response.data.value) {
                localStorage.setItem(
                    "set_OneForUI_timezone",
                    new Date().getTime()
                )
                return response.data.data
            } else {
                throw new Error("errordata")
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    async fetchUser(endpoint) {
        // Token is required but not available
        if (this.options.tokenRequired && !this.$auth.getToken(this.name)) {
            return
        }

        // User endpoint is disabled.
        if (!this.options.endpoints.user) {
            this.$auth.setUser({})
            return
        }

        // Try to fetch user and then set
        const _accessToken = this.$auth.getToken("customLocal")
        this.$auth.setToken(this.name, _accessToken)
        this._setToken("Bearer " + _accessToken)

        // This will stored data for 10mins.
        let setOneForUI = localStorage.getItem("set_OneForUI")
        if (setOneForUI) {
            const aa = localStorage.getItem("set_OneForUI_timezone")
                ? localStorage.getItem("set_OneForUI_timezone")
                : new Date().getTime() - 12 * 60 * 1000
            const now = new Date().getTime()
            const distance = now - aa
            const seconds = Math.floor(distance / 1000)
            const minutes = Math.floor(seconds / 60)
            if (minutes > 10) {
                localStorage.removeItem("set_OneForUI")
                localStorage.removeItem("set_OneForUI_timezone")
                setOneForUI = null
            }
        }

        if (setOneForUI) {
            const response = await JSON.parse(encryptor.decrypt(setOneForUI))
            if (response?.data?.data) {
                this.$auth.setUser(response.data.data)
            } else {
                setOneForUI = null
            }
        }
        if (
            setOneForUI === null ||
            setOneForUI === undefined ||
            setOneForUI === ""
        ) {
            this.getDataFromapi(_accessToken)
                .then((data) => {
                    sessionStorage.setItem("AuthData", true)
                    this.$auth.setUser(data)
                })
                .catch((error) => {
                    if (
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.errorMsg
                    ) {
                        sessionStorage.setItem("vpnerror", true)
                    }
                    return this.$auth.reset()
                })
        }

        try {
            // jwt time expiration api
            const { response } = await this.$auth.request(
                {
                    baseURL: process.env.USER_V2_API_URL,
                    data: { token: _accessToken }
                },
                this.options.endpoints.verifyjwt,
                true
            )
            if (response.data.value) {
                const todayDate = new Date()
                const expDate = new Date(response.data.data.expDate)
                // jwt expire time calculations
                const todayTime = expDate - todayDate

                // logout code after jwt expirations
                if (todayTime > 0) {
                    setTimeout(() => {
                        this.logout()
                    }, todayTime)
                } else {
                    setTimeout(() => {
                        this.logout()
                    }, 100)
                }
            } else {
                setTimeout(() => {
                    this.logout()
                }, 100)
            }
        } catch (error) {
            setTimeout(() => {
                this.logout()
            }, 100)
        }
    }

    async logout(endpoint) {
        // Only connect to logout endpoint if it's configured
        const accessToken = this.$auth.getToken("customLocal")
        localStorage.clear()
        sessionStorage.clear()
        if (this.options.endpoints.logout) {
            await this.$auth
                .requestWith(this.name, endpoint, {
                    baseURL: process.env.USER_V2_API_URL,
                    ...this.options.endpoints.logout,
                    data: { accessToken }
                })
                .catch(() => {})
        }
        this.removeLocalStorage()
        // But reset regardless
        return this.$auth.reset()
    }

    removeLocalStorage() {
        localStorage.removeItem("wacCasinoIds")
    }
}
